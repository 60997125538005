const firebase = require('../../firebase');
import formatEmailForFirebaseUser from '../format-email-for-firebase-user/formatEmailForFirebaseUser';
const { TOKEN, PNBPER, PNB } = require('../../constants/banks');

export default async function formatEmailIfNotExistsInFirebase(
    userEmail: string,
    scope: string,
    password: string,
) {
    // Replace PNBPER Scope with PNB - APE-2520
    if (scope === PNBPER) {
        scope = PNB;
    }

    let firebaseEmail = userEmail;
    if (scope !== TOKEN) {
        const methods = await firebase.auth().fetchSignInMethodsForEmail(userEmail);
        if (methods.length !== 0) {
            try {
                await firebase.auth().signInWithEmailAndPassword(userEmail, password);
                return userEmail;
            } catch (e) {
                firebaseEmail = formatEmailForFirebaseUser(userEmail, scope);
            }
        } else {
            firebaseEmail = formatEmailForFirebaseUser(userEmail, scope);
        }
    }
    return firebaseEmail;
}
